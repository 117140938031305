import { Typography } from 'antd';
import { InputExplanationWrapper } from './InputExplanationWrapper';
import { Redirects } from '../../../../constants/redirects';

export const SignReportExplanation = () => (
  <InputExplanationWrapper>
    <Typography.Paragraph type="secondary" style={{ marginTop: 4 }}>
      By enabling "Sign Report", the generated report will be cryptographically signed and a signature file downloaded together with the report. This is
      required if the report is used to ensure end-to-end integrity. If the report file is altered, the signature won't match anymore, thus we suggest to use
      PDF. Learn more about integrity, checksums and signatures{' '}
      <Typography.Link href={Redirects.WIKI_PACTS_INTEGRITY} target="_blank">
        here
      </Typography.Link>
      .
    </Typography.Paragraph>
  </InputExplanationWrapper>
);
