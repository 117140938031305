import { Typography } from 'antd';
import styled from 'styled-components';

import { THEMES, useTheme } from '../../theme/ThemeProvider';

import type { ComponentForDevices } from '../helper/deploymentTypes';


const Text = styled(Typography.Text)<{ isAllInstalled: boolean, isLightTheme: boolean }>`
	color: ${({ theme, isAllInstalled, isLightTheme }) => {
		if (isAllInstalled) {
			return isLightTheme ? theme.primitivesLight.green[6] : theme.colorSuccess;
		}

		return isLightTheme ? theme.primitivesLight.orange[5] : theme.primitivesDark.orange[7];
	}};
`;

type Props = {
  component: ComponentForDevices;
};

export const InstalledStatus = (props: Props) => {
	const { component } = props;

	const [theme] = useTheme();
  const isLightTheme = theme === THEMES.LIGHT;

	const isAllInstalled = component.allInstalled;
	const installed = component.targets.filter((t) => t.isInstalled);
	const label = `${installed.length}/${component.targets.length} installed`;

	return <Text isLightTheme={isLightTheme} isAllInstalled={isAllInstalled}>{label}</Text>;
}