import { EditOutlined, FileMarkdownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Tabs, Form, Tooltip, Typography } from 'antd';
import { Rule } from 'antd/lib/form';

import { MarkdownRenderer } from './MarkdownRenderer';
import { DescriptionTextAreaInput } from './DescriptionTextAreaInput';
import { MARKDOWN_ENABLED_TEXT } from '../../../constants/texts';
import { Redirects } from '../../../constants/redirects';

interface MarkdownFormItemProps {
  label?: string;
  field: string[];
  labelAlign?: 'left' | 'right';
  rules?: Rule[];
  optional?: boolean;
  descriptionMinRows?: number;
  descriptionMaxRows?: number;
  placeholder?: string;
  style?: any;
  shouldUpdate: (prevValuse: any, currentValues: any) => boolean;
}

export const MarkdownFormItem = (props: MarkdownFormItemProps) => {
  const { label, field, labelAlign, rules, optional, descriptionMinRows = 6, descriptionMaxRows = 15, shouldUpdate } = props;

  const HowTo = () => (
    <span>
      {label} <Tooltip title="How To: Release Notes with Markdown">
        <Typography.Link href={Redirects.WIKI_PACTS_RELEASE_NOTES} target="_blank">
          {<QuestionCircleOutlined />}
        </Typography.Link>
      </Tooltip>
    </span>
  );

  return (
    <Form.Item label={<HowTo />} labelAlign={labelAlign} required={!optional} shouldUpdate={shouldUpdate} style={{ marginTop: 16, ...(props.style ?? {}) }}>
      {({ getFieldValue }) => {
        return (
          <Tabs defaultActiveKey="e" style={{ marginTop: -6 }}>
            <Tabs.TabPane
              tab={
                <span>
                  <EditOutlined /> Edit
                </span>
              }
              key="e"
            >
              <Form.Item noStyle name={field} rules={rules}>
                <DescriptionTextAreaInput
                  autoSize={{ minRows: descriptionMinRows, maxRows: descriptionMaxRows }}
                  placeholder={`${props.placeholder ? `${props.placeholder}\n` : ''}${MARKDOWN_ENABLED_TEXT}`}
                  styles={{ background: 'transparent' }}
                />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <FileMarkdownOutlined /> Preview
                </span>
              }
              key="p"
            >
              <MarkdownRenderer>{getFieldValue(field)}</MarkdownRenderer>
            </Tabs.TabPane>
          </Tabs>
        );
      }}
    </Form.Item>
  );
};
