import { Flex, Typography, Empty } from 'antd';
import { useInAppNavigate } from '../../../contexts/navigation/hooks';
import { Redirects } from '../../../constants/redirects';

export const NoEnvironmentsMessage = (props: { projectId: number; hideCreateHelp?: boolean }) => {
  const navigate = useInAppNavigate();
  return (
    <>
      <br />
      <br />
      <Flex justify="center">
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          There are no environments in this project. <br />
          <br />
          {!props.hideCreateHelp ? (
            <Typography.Paragraph>
              You can create environments for this project on{' '}
              <Typography.Link
                onClick={() => {
                  navigate(`/projects/${props.projectId}/deployments/environments`);
                }}
              >
                Deployments
              </Typography.Link>
              .
            </Typography.Paragraph>
          ) : null}
        </Typography.Paragraph>
      </Flex>
      <br />
      <Empty description="" />
      <br />
      <br />
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        If you want to learn more about environments, please check our wiki page{' '}
        <Typography.Link target="blank" href={Redirects.WIKI_PACTS_DEPLOYMENT_PLAN}>
          here
        </Typography.Link>
        .
      </Typography.Paragraph>
    </>
  );
};
