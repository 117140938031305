import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { EntityAdministration } from '../contexts/administration/components/EntityAdministration';
import { ProjectMemberAdministration } from '../contexts/administration/components/ProjectMemberAdministration';
import { EngineeringToolDetails } from '../contexts/engineeringTools/components/EngineeringToolDetails';
import { EngineeringToolsView } from '../contexts/engineeringTools/components/EngineeringToolsView';
import { EngineeringToolVersions } from '../contexts/engineeringTools/components/EngineeringToolVersions';
import { Home } from '../contexts/home/components/Home';
import { OpenInImpactsPage } from '../contexts/impacts/components/OpenInImpactsPage';
import { ProjectRouter } from '../contexts/projects/components/ProjectRouter';
import { Login } from '../contexts/session/components/Login';
import { NotFound } from '../contexts/shared/components/NotFound';
import { CommonAppsView } from '../contexts/softwareApps/components/CommonSoftwareAppsView';
import { SoftwareAppDetails } from '../contexts/softwareApps/components/SoftwareAppDetails';
import { SoftwareAppVersions } from '../contexts/softwareApps/components/SoftwareAppVersions';
import { PacTSLayoutRootElement, PacTSRouteProto } from './PacTSRoutes';
import { WebsocketPoller } from '../api/shared/messaging/WebsocketPoller';
import { SystemNotificationDashboard } from '../contexts/notifications/components/SystemNotificationsDashboard';
import { NotificationLink } from '../contexts/notifications/components/NotificationLink';
import { ErrorBoundary } from '../contexts/shared/components/ErrorBoundary';
import { Unauthorized } from '../contexts/session/components/Unauthorized';
import { SessionHookProvider } from '../contexts/session/components/SessionHookProvider';
import { ReportGateway } from '../contexts/reports/components/ReportGateway';
import { SessionAdministration } from '../contexts/administration/components/SessionAdministration';
import AllResources from '../contexts/pactsformation/components/AllResources';
import { usePermissions } from '../contexts/session/hooks/usePermissions';
import { PacTSPermissions } from '@pacts/permissions-lib';
import { useContext } from 'react';
import { useSession } from '../contexts/session';
import { PacTSContext } from '../state/store';
import { PageLoading } from '../contexts/shared/components/PageLoading';
import { UserAdministration } from '../contexts/administration';
import { NotFoundForwarder } from './NotFoundForwarder';
import { PacTSRedirectGateway } from '../contexts/redirects/PacTSRedirectRouter';
import { REDIRECT_BASE } from '../constants/redirects';

const publicRoutes: PacTSRouteProto[] = [
  {
    element: <OpenInImpactsPage />,
    path: '/impacts',
    layoutHidden: true,
    public: true,
    requiredPermissions: []
  },
  {
    element: <Login />,
    path: '/login',
    public: true,
    layoutHidden: true,
    requiredPermissions: []
  },
  {
    element: <NotFound />,
    path: '/404',
    public: true,
    requiredPermissions: []
  },
  {
    element: <PacTSRedirectGateway />,
    path: `${REDIRECT_BASE}/*`,
    public: true,
    layoutHidden: true,
    requiredPermissions: []
  },
  {
    element: <NotFoundForwarder />,
    path: '*',
    public: true,
    requiredPermissions: []
  }
];
const authenticatedRoutes: PacTSRouteProto[] = [
  {
    element: <Home />,
    path: '/',
    requiredPermissions: []
  },
  {
    element: <EngineeringToolsView />,
    path: '/tools',
    requiredPermissions: [['engineeringSvc$getTools', 'engineeringSvc$getTool$specific']]
  },
  {
    element: <CommonAppsView />,
    path: 'apps',
    requiredPermissions: [['engineeringSvc$getCommonSoftwareApps', 'engineeringSvc$getCommonSoftwareApp$specific']]
  },
  {
    element: <Unauthorized />,
    path: '/unauthorized',
    layoutHidden: true,
    requiredPermissions: []
  },
  {
    element: <ProjectMemberAdministration />,
    path: '/project-member-administration',
    requiredPermissions: [['engineeringSvc$addProjectMember', 'engineeringSvc$addProjectMember$specific']]
  },
  {
    element: <UserAdministration />,
    path: '/user-administration',
    requiredPermissions: [['userSvc$updateRolesOfUser']]
  },
  {
    element: <EntityAdministration />,
    path: '/entity-administration',
    requiredPermissions: [['all$unrestrictedAdministration']]
  },
  {
    element: <SessionAdministration />,
    path: '/session-administration',
    requiredPermissions: [['userSvc$restrictedDeleteRefreshToken']]
  },
  {
    element: <SystemNotificationDashboard />,
    path: '/notification-administration',
    requiredPermissions: [['notificationSvc$restrictedNotificationsSendRequest']]
  },
  {
    element: <ReportGateway />,
    layoutHidden: true,
    path: '/projects/:projectid/reports/goto/:sourceid/:revision/:reportid',
    requiredPermissions: []
  },
  {
    element: <ProjectRouter />,
    path: '/projects/*',
    requiredPermissions: [['engineeringSvc$getProject', 'engineeringSvc$getProjects', 'engineeringSvc$getProject$specific']]
  },
  {
    element: <EngineeringToolVersions />,
    path: '/tools/:id/versions',
    requiredPermissions: [
      ['engineeringSvc$getToolVersions', 'engineeringSvc$getToolVersion', 'engineeringSvc$getToolVersions$specific', 'engineeringSvc$getToolVersion$specific']
    ]
  },
  {
    element: <EngineeringToolDetails />,
    path: '/tools/:id/details',
    requiredPermissions: [['engineeringSvc$getTool', 'engineeringSvc$getTools', 'engineeringSvc$getTool$specific'], ['webui$showComponentDetails']]
  },
  {
    element: <SoftwareAppDetails />,
    path: '/apps/:id/details',
    requiredPermissions: [
      ['engineeringSvc$getCommonSoftwareApp', 'engineeringSvc$getCommonSoftwareApps', 'engineeringSvc$getCommonSoftwareApp$specific'],
      ['webui$showComponentDetails']
    ]
  },
  {
    element: <SoftwareAppVersions />,
    path: '/apps/:id/versions',
    requiredPermissions: [
      [
        'engineeringSvc$getCommonSoftwareAppVersions',
        'engineeringSvc$getCommonSoftwareAppVersion',
        'engineeringSvc$getCommonSoftwareAppVersions$specific',
        'engineeringSvc$getCommonSoftwareAppVersion$specific'
      ]
    ]
  },
  {
    element: <AllResources />,
    path: '/pactsformation',
    requiredPermissions: [['pactsFormationSvc$unrestrictedAdministration']]
  },
  {
    element: <NotificationLink />,
    path: '/notification/:id',
    requiredPermissions: [['notificationSvc$notificationsGetRequest']]
  }
];

const mapRoute = (r: PacTSRouteProto): RouteObject => {
  return {
    path: r.path,
    element: <PacTSLayoutRootElement route={r} />,
    errorElement: <ErrorBoundary />,
    children: []
  };
};

const filterRoutes = (routes: PacTSRouteProto[], permissions: PacTSPermissions): PacTSRouteProto[] => {
  const filtered = routes.filter((route) => {
    if (route.public) return true;
    if (route.requiredPermissions.length < 1) return true;

    return route.requiredPermissions.every((orGroup) => {
      return orGroup.some((p) => {
        if (p.endsWith('$specific')) {
          return (permissions[p] as () => [])().length > 0;
        }
        return permissions[p] === true;
      });
    });
  });

  return filtered;
};

export const PacTSRouter = () => {
  const permissions = usePermissions();
  const allRoutes = [...authenticatedRoutes, ...publicRoutes];
  const accessFilteredRoutes = filterRoutes(allRoutes, permissions);
  const router = createBrowserRouter(accessFilteredRoutes.map(mapRoute));

  // while session is initial, do not render routes
  const [state] = useContext(PacTSContext);
  const session = useSession(state);

  const loginPath = window.location.pathname === '/login';

  // when on login page, always render router to prevent flickering
  const loading = session.state === 'initial' && !loginPath;

  return (
    <WebsocketPoller>
      <SessionHookProvider>
        {loading && <PageLoading />}
        {!loading && <RouterProvider router={router} />}
      </SessionHookProvider>
    </WebsocketPoller>
  );
};
