import { Button, Divider, Flex, List, Skeleton, Space, Switch, Typography } from 'antd';
import { useDeploymentPlans } from '../hooks/useDeploymentPlans';
import { LockDeploymentPlanDeploymentButton } from './LockDeploymentPlanDeploymentButton';
import { DeploymentPlanReportDownloadButton } from './DeploymentPlanReportDownloadButton';
import { EmptyMessage } from '../../shared/components/EmptyMessage';
import { usePermissions } from '../../session';
import { FileTextOutlined } from '@ant-design/icons';
import { formatDateTime } from '../../shared/components/formatDate';
import { DeploymentPlanInstallationStatusIndicator } from './DeploymentPlanInstallationStatusIndicator';
import { useState } from 'react';
import { DeploymentPlanMeta, Project } from '../../../api';
import { ScrollDialog } from '../../../contexts/layout/components/ScrollDialog';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { useDeploymentEnvironments } from '../hooks/useDeploymentEnvironments';
import { useNavigate } from 'react-router-dom';
import { MarkdownRenderer } from '../../../contexts/shared/components/MarkdownRenderer';
import { DeploymentPlanCardContent } from '.';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { getReleaseName } from '../helper/extractor';
import { Redirects } from '../../../constants/redirects';

export const DeploymentPlans = (props: { project: Project; envId: string }) => {
  const navigate = useNavigate();
  const deploymentPlans = useDeploymentPlans(props.project.idProject, props.envId);
  const depEnvs = useDeploymentEnvironments(props.project.idProject);
  const selectedEnv = depEnvs.data?.find((env) => env.id === props.envId);
  const nullSafePlantList = deploymentPlans.data || [];
  const activePlans =
    nullSafePlantList
      .filter((plan) => !plan.locked)
      .sort((a, b) => {
        return Date.parse(b.createdAt!) - Date.parse(a.createdAt!);
      }) || [];
  const activePlan: DeploymentPlanMeta | undefined = activePlans?.[0];
  const permissions = usePermissions({ projectId: props.project.idProject.toString(), environmentId: props.envId });
  const [showPartial, setShowPartial] = useSearchParameter('partial', undefined);
  const [showDescription, setShowDescription] = useState(false);
  const isLoading = deploymentPlans.isLoading || depEnvs.isLoading;

  const table = (plans: DeploymentPlanMeta[]) => (
    <>
      <List
        dataSource={plans}
        grid={{ column: 1, gutter: 32 }}
        pagination={
          plans.length > 4 && {
            total: plans?.length || 0,
            showSizeChanger: true,
            defaultPageSize: 4,
            pageSizeOptions: ['4', '10', '20']
          }
        }
        renderItem={(plan: DeploymentPlanMeta) => (
          <List.Item>
            <DeploymentPlanCardContent shouldShowPartial={showPartial === 'true'} project={props.project} envId={props.envId} meta={plan} />
          </List.Item>
        )}
      />
    </>
  );

  const handleChangePartiallyInstalled: SwitchChangeEventHandler = (checked) => {
    setShowPartial(checked ? 'true' : 'false');
  };

  return (
    <>
      <ScrollDialog
        styles={{
          body: {
            minHeight: '50vh'
          }
        }}
        open={showDescription}
        onCancel={() => {
          setShowDescription(false);
        }}
        title="Description"
        footer={null}
      >
        <MarkdownRenderer>{activePlan?.description || 'N/A'}</MarkdownRenderer>
      </ScrollDialog>
      {!!activePlan && (
        <Space style={{ fontSize: '14px', marginBottom: '30px' }} split={<Divider style={{ height: '33px' }} type="vertical" />} wrap>
          {`Name: ${activePlan.name}`}

          <DeploymentPlanInstallationStatusIndicator
            isFullyInstalled={activePlan.fullyInstalled}
            projectId={props.project.idProject}
            envId={props.envId}
            planId={activePlan.id}
          />

          {`Creator name: ${activePlan.createdByName}`}
          {`Creation date: ${formatDateTime(new Date(activePlan.createdAt!))}`}
          {`Project Software Release: ${getReleaseName(activePlan)}`}

          <Button onClick={() => setShowDescription(true)} style={{ padding: 0 }} type="link" icon={<FileTextOutlined />}>
            Description
          </Button>
        </Space>
      )}
      {activePlans.length > 0 && (
        <Flex style={{ marginBottom: '15px' }} justify="space-between">
          <Space>
            {/* We keep the component commented for future implementation */}
            {/* <SegmentedWrapper>
            <Segmented options={tabOptions} />
          </SegmentedWrapper> */}
            <Space>
              <Switch checked={showPartial === 'true'} onChange={handleChangePartiallyInstalled} />
              Partially installed only
            </Space>
          </Space>
          {activePlan != null && (
            <Space key="planExtra">
              {permissions.deploymentSvc$getDeploymentPlanReport && (
                <DeploymentPlanReportDownloadButton projectId={props.project.idProject} envId={props.envId} key="reportbutton" deploymentPlan={activePlan} />
              )}
              {permissions.deploymentSvc$lockDeploymentPlan && (
                <LockDeploymentPlanDeploymentButton projectId={props.project.idProject} envId={props.envId} key="lockbutton" deploymentPlan={activePlan} />
              )}
            </Space>
          )}
        </Flex>
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {activePlans.length < 1 ? (
            <>
              <br />
              <br />
              <Flex justify="center">
                <Typography.Paragraph style={{ textAlign: 'center' }}>
                  There are no open deployments in {selectedEnv?.name ?? 'unknown environment'}. <br />
                  <br />
                  <Typography.Paragraph>
                    You can create a deployment plan from {props.project.name}{' '}
                    <Typography.Link
                      onClick={() => {
                        navigate(`/projects/${props.project.idProject}/apps`);
                      }}
                    >
                      Project Software
                    </Typography.Link>
                    .
                  </Typography.Paragraph>
                </Typography.Paragraph>
              </Flex>
              <br />
              <EmptyMessage description="" />
              <br />
              <br />
              <Typography.Paragraph style={{ textAlign: 'center' }}>
                If you want to learn how to create a deployment, please check our wiki page{' '}
                <Typography.Link target="blank" href={Redirects.WIKI_PACTS_DEPLOYMENT_PLAN}>
                  here
                </Typography.Link>
                .
              </Typography.Paragraph>
            </>
          ) : (
            table(activePlans)
          )}
        </>
      )}
    </>
  );
};
