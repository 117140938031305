export const REDIRECT_BASE = '/redirect';

const AvailableRedirects = {
  SERVICE_DESK: 'service-desk',

  WIKI_PACTS: 'wiki-pacts',
  WIKI_PACTS_DEPLOYMENT_PLAN: 'wiki-pacts-deployment-plan',
  WIKI_PACTS_RELEASE_NOTES: 'wiki-pacts-release-notes',
  WIKI_PACTS_TOKENS: 'wiki-pacts-tokens',
  WIKI_PACTS_INTEGRITY: 'wiki-pacts-integrity',
  WIKI_IMPACTS: 'wiki-impacts',

  TEAMS: 'teams-pacts',

  SIGNING_KEY: 'signing-key',

  SE_IMPRINT: 'corporate-imprint',
  SE_PRIVACY_NOTICE: 'corporate-privacy-notice',
  SE_TERMS_OF_USE: 'corporate-terms-of-use',

  USER_SATISFACTION_PROD: 'user-satisfaction-production',
  USER_SATISFACTION_DEV: 'user-satisfaction-dev',

  ICS_PORTAL: 'ics-portal'
};

export type RedirectLink = string & { __nominal: unknown };
export type RedirectKeys = keyof typeof AvailableRedirects;

type RedirectLinksMap = { [key in RedirectKeys]: RedirectLink };

const getRedirectLink = <K extends RedirectKeys>(to: K): RedirectLink => {
  // make sure the search parameter is uri conform
  return `${REDIRECT_BASE}?to=${encodeURIComponent(AvailableRedirects[to])}` as RedirectLink;
};

export const Redirects: RedirectLinksMap = Object.keys(AvailableRedirects).reduce((obj, key) => {
  obj[key as RedirectKeys] = getRedirectLink(key as RedirectKeys);
  return obj;
}, {} as RedirectLinksMap);
